import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Home from './pages/home';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCogs, faUserTie, faBalanceScale, faBed } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCogs, faUserTie, faBalanceScale, faBed)


//import Contact from './components/pages/contact';
/* Put back in? <Route path='/contact' component={Contact} /> */
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
