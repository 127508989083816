import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '../styles/services';
import Service from '../modals/serviceModal';
const Services = () => {
	let consulting = {
		operational: {
			paragraph: "Operating skilled nursing facilities is challenging in any environment. JHG has developed tools to assist administrators and managers with departmental oversight with a focus on quality outcomes and business office support. Taking into account staffing challenges, changes in reimbursement systems, and industry mandates, just to name a few, the following are programs we have built to help:",
			whatWeHelpWith: ['Quality Outcomes Review', 'Systems Review', 'QAPI Review', 'Staff Morale', 'Departmental Analysis', 'Mandatory Inservices', 'Staffing Turnover', 'Time Management', '24 Hour Care Planning', 'Revenue Enhancement Opportunities', 'Strategic Planning', 'Expense and Labor Controls', 'Corporate Ethics', 'Smart Tool Integration', 'Business Associate Agreements', 'Vertically Integrating Hospitals', '5-Star Consulting']
		},
		clinical: {
			paragraph: "JHG engineers custom programs that assist your organization with meeting clinical goals. Our services reduce the chance of failure and maximize outcomes. We educate and train your interdisciplinary teams to customize and automate clinical workflows. Specializing in the PointClickCare EMR system (or whichever EMR system you use) our clinicians will train your teams to utilize data to effectively manage case loads. We bring your EMR to life!",
			whatWeHelpWith: ['QAPI', 'Reducing Hospital Readmissions', 'Falls Reduction Programs', 'Antibiotic Stewardship', 'Pulmonary Programs', 'Telemedicine', 'ADL Reviews', 'Infection Disease Specialists', 'CHF Programs', 'Sepsis Programs', 'Clinical Excellence', 'Physician Relationships', 'Restorative Programing', 'Proper Documentation Protocols']
		},
		turnaround: {
			paragraph: "Working with all facets of your operation, whether you have seen a decline in net margin or you are over-levered and have negative cash balances; JHG will analyze your operation to identify revenue gain and expense adjustments that are necessary while always keeping an eye on Quality Care and customer-centric services.",
			whatWeHelpWith: ['Revenue Enhancements', 'Budgeting', '13-Week Cash Flows', 'Debt Consolidation/Reorganization', 'Lender Relationships', 'Staffing Analysis', 'Census Development']
		}
	};
	let riskManagement = {
		providers: {
			bullets: ['JHGs Customer Centric Program', 'Training & Implementation on the Yes! Philosophy', 'JHGs Risk Reporter Licensable Software']
		},
		insurance: {
			bullets: ['Fast Track Assessment', 'Comprehensive Assessment', 'JHGs Risk Reporter Licensable Software', 'Consulting Services']
		}
	};

	let marketing = {
		paragraph: '',
		bullets: ['Census Development', 'Hospital Presence', 'Direct Marketing to Communities', 'Digital Presence (Website, Mobile Applications, Social Media)', 'Branding (Custom Marketing Materials, Merchandising)']
	};
	let qualityCompliance = {
		bullets: ['Mock Surveys', 'Plan of Correction Development', 'IDR Development', 'Quality Indicator Review and QAPI Planning', 'Quality Assurance and QAPI Committee Development', 'Safety Committee Development', 'Facility Service Review', 'Safety Walk-throughs and Risk Identification', 'Fall Prevention and Alarm Restraint Reductions', 'Departmental Programming Reviews and Enhancements', 'In-service Training']
	};

	let leadership = {
		one: 'At JHG, we believe that accountability is one of the conduits for success.  To achieve accountability throughout all levels of an organization, there must be organizational health through strong leadership.  We believe organizational health and leadership, set the foundation for which personal and organizational accountability can thrive.',
		two: 'Our model is based on a wide range of leadership principles, but heavily rooted in the belief that organizational health is the most important thing within any company (yet it is often disregarded). There is one competitive advantage that is available to any organization that wants it and yet it is largely ignored.  It is called ORGANIZATIONAL HEALTH, and it occupies a lot of time and attention of extraordinary executives and leadership teams, and with the right amount of focus and attention, is sustainable despite the challenges an organization will face.',
		three: 'A healthy organization is one that has less politics and confusion, higher morale and productivity, lower unwanted turnover, and lower recruiting costs than an unhealthy one.  A healthy organization can endure even the toughest of challenges, even those that would cause most organizations to cease to exist. Leadership sets the basis for organizational health and the most influential factors towards that health are trust and healthy conflict / debate.  Leadership should be found and cultivated at all levels of the organization, from Team Leaders to Department Heads to Executives.  Having a common set of core values and leadership principles with the clarity to communicate them effectively, will have a lasting impact on an organization’s health, quality, and ultimately success.',
		four: 'JHG can customize leadership training for all levels and sizes of your organization and we can work with you to develop a program that meets your organization’s unique needs.  JHG has leadership development programming that can be provided as a one-time training or a larger, long-term leadership development program.',
		five: 'At JHG, our leadership consultants can spend time with your team and evaluate your organization to see what is working and where improvements in accountability and leadership can be made.  Our team will provide you with a summary of our review and can customize a leadership development plan based on your budget, your needs, and your time frame. Give JHG a call today and let’s discuss how we can partner to change the game of health care for the betterment of all seniors together.'
	};
	return (
		<Container id='services'>
			<div className='header'>
				<h2>Services</h2>
			</div>
			<div className='services'>
				<div className='serviceSection'>
					<div className='serviceName'>
						<h4>Consulting</h4>
					</div>
					<FontAwesomeIcon className='mIcon' icon='cogs' />
					<div className='serviceText'>
						<p>
							JHG's philosophy of <strong>Quality Before Revenue </strong>is the cornerstone to driving appropriate revenue and maximizing outcomes; which is critical
							to aligning with current reimbursment systems. Our consulting approach is unique in an industry that often times dismisses customer service. Our customer 
							centric progam utilizes a holistic approach that includes 
							not only your residents and their families, but your most important asset, your employees.
							<br /><br />
							The most significant redesign in the Medicare reimbursement program since 1998, PDPM went into effect October 1st, 2019. The JHG model of <strong>Quality Before Revenue </strong> will assure your clinical teams are properly trained to understand and work in this new regulatory environment. Our teachings allow your organization to best strategize under the new Patient Driven Payment Model. The JHG team has developed and spoken on national panels to help train and strategize for this monumental change in Medicare reimbursement.
							<br /><br />
							JHG has developed a back-office solution that allows smaller organizations and independently owned facilities to tap into years of experience in billing and collecting healthcare receivables. Our billing specialists are well-versed in Medicare, Medicaid, Insurance, Private and Crossover billings. If your organization is having difficulty with billing and reimbusements or any element of the business office, JHG is an excellent resource.
						</p>
					</div>
					<div className='modalHold'>
						<Service whatWeHelpWith={consulting.operational.whatWeHelpWith} paragraph={consulting.operational.paragraph} secondTitle="What We Help With" title="Operational Consulting" buttonLabel="Service" className="serviceModal"/>
						<Service whatWeHelpWith={consulting.clinical.whatWeHelpWith} paragraph={consulting.clinical.paragraph} secondTitle="What We Help With" title="Clinical Consulting" buttonLabel="Service" className="serviceModal"/>
						<Service whatWeHelpWith={consulting.turnaround.whatWeHelpWith} paragraph={consulting.turnaround.paragraph} secondTitle="What We Help With" title="Turnaround Consulting" buttonLabel="Service" className="serviceModal"/>
					</div>
				</div>
				<div className='serviceSection'>
					<div className='serviceName'>
						<h4>Leadership &amp; Accountability</h4>
					</div>
					<FontAwesomeIcon className='mIcon' icon='user-tie' />
					<div className='serviceText'>
						<p>{leadership.one}</p>
						<br /><br />
						<p>{leadership.two}</p>
						<br /><br />
						<p>{leadership.three}</p>
						<br /><br />
						<p><strong>How can JHG improve your organizational health through leadership and accountability?</strong></p>
						<br /><br />
						<p>{leadership.four}</p>
						<br /><br />
						<p>{leadership.five}</p>
					</div>
				</div>
				<div className='serviceSection'>
					<div className='serviceName'>
						<h4>Risk Management</h4>
					</div>
					<FontAwesomeIcon className='mIcon' icon='balance-scale' />
					<div className='serviceText'>
						<p>JHG has developed a state of the art risk management tool that can assist skilled nursing facility operators in mitigating circumstances that lead to insurance losses which result in higher liability insurance premiums. Utilizing elements of our customer-centric program with your residents, their families, and your employees; coupled with our state of the art technology has helped reduce the instances of litigation in those organizations we serve. Typically we see savings as much as 33% against industry averages for organizations utilizing our platform.
						</p>
					</div>
					<div className='modalHold'>
						<Service whatWeHelpWith={riskManagement.providers.bullets} secondTitle="What We Help With" title="For Providers" buttonLabel="Service" className="serviceModal"/>
						<Service whatWeHelpWith={riskManagement.insurance.bullets} secondTitle="What We Help With" title="For Insurance Providers" buttonLabel="Service" className="serviceModal"/>
					</div>
				</div>
				<div className='serviceSection'>
					<div className='serviceName'>
						<h4>Marketing &amp; Customer Service</h4>
					</div>
					<FontAwesomeIcon className='mIcon' icon='bed' />
					<div className='serviceText'>
						<p>When evaluating any new customer, JHG will start with our customer centric program and analyze existing employee training protocols to determine aspects of the customer service program that will be maintained. This program will enhance the marketing efforts of your organization. All employees will be trained on the JHG philosophy of YES!. <br /><br />JHG's YES! philosophy is to strive to deliver on every reasonable request made by residents and their families. It is imperative that all staff from the Chief Executive to the volunteers understand and adhere to the same philosophies system wide. <br /><br />We recognize the best companies in the world offer the best customer service experience, the JHG YES! program is built on that philosophy. Often times in the senior living sector residents and their families are treated like patients consistent with regulatory protocol, and not like customers of a business. Our tried and true program will bring back the idea of "The Customer is Always Right" and the organizations that we serve deploy that mentality with all employee touch points.
						</p>
					</div>
					<div className='modalHold'>
						<Service whatWeHelpWith={marketing.bullets} secondTitle="What We Help With" title="Marketing" buttonLabel="Service" className="serviceModal"/>
					</div>
				</div>
				<div className='serviceSection'>
					<div className='serviceName'>
						<h4>Quality and Compliance</h4>
					</div>
					<FontAwesomeIcon className='mIcon' icon='bed' />
					<div className='serviceText'>
						<p>Our consultants can spend time with your team and evaluate your organization to see what is working and where improvements in quality and compliance can be made.  Our team will provide you with a summary of our review and can customize a quality development plan based on your budget, your needs, and your timeframe.
						</p>
					</div>
					<div className='modalHold'>
						<Service whatWeHelpWith={qualityCompliance.bullets} secondTitle="What We Help With" title="Programs and Enhancements" buttonLabel="Service" className="serviceModal"/>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default Services;