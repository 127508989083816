import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SecondaryServiceModal from './secondaryServiceModal';
import Logo from '../images/jhgLogo.png';

const Service = (props) => {

	const {
		buttonLabel,
		className,
		title,
		paragraph,
		secondTitle,
		whatWeHelpWith
	} = props;

	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);
	let elements = props.whatWeHelpWith;
	console.log(elements);
	let elementsList = elements.map((val) => {
		return <li>{val}</li>;
	});
	return (
		<div className='modalDiv' onClick={toggle}>
			<div className='modalButtonHolder'>
				<p>{props.title}</p>
			</div>
			<Modal size='xl' id="modal" isOpen={modal} toggle={toggle} className={className}>
				<ModalHeader className="modalHeader" toggle={toggle}>
					<h6>{props.title}</h6>
				</ModalHeader>
				<ModalBody className="modalBody">
					<p>{props.paragraph}</p>
					<h4>{props.secondTitle}</h4>
					<ul>
						{elementsList}
					</ul>
					<img src={Logo} />
				</ModalBody>
			</Modal>
		</div>
	);
}

export default Service;