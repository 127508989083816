import React from 'react';
import { Container } from '../styles/nav';
import Logo from '../images/logo.png';
import Contact from '../modals/contactModal';

const Nav = () => {
	return (
		<Container>
			<nav>
				<img src={Logo} style={{height: '150px'}}/>
				<div className="middleSection">
					<h1>Jordan Healthcare Group, LLC</h1>
					<a href='#services'><p>Solution Driven Healthcare</p></a>
				</div>
				{/*<Contact buttonlabel="Contact" className="contactModal"/>*/}
			</nav>
		</Container>
	);
}

export default Nav;