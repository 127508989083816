import styled from 'styled-components';
import { Purple, Black, White, Orange, Grey } from './colors';
import { Wrap, Direction, Flex } from './mixins';

export const Container = styled.div`
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	.header {
		width: 100%;
		height: auto;
		background-color: ${Purple};
		color: ${White};
		h2 {
			margin: 0 auto;
			text-align: center;
			padding: 7.5px;
		}
	}
	.services {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		${Flex('row', 'wrap')};
		justify-content: center;
		align-items: center;
		.serviceSection {
			@media (max-width: 1000px) {
				width: 85%;
			}	
			margin: 15px;
			padding: 0;
			height: auto;
			${Flex('column', 'nowrap')};
			width: 85%;
			background-color: ${Grey};
			border-radius: 10px;
			justify-content: space-between;
			box-shadow: 0px 0px 12.5px rgba(0,0,0,0.6);
		    -webkit-box-shadow: 0px 0px 12.5px rgba(0,0,0,0.6);
		    -moz-box-shadow: 0px 0px 12.5px rgba(0,0,0,0.6);
			.serviceName {
				background-color: ${White};
				color: ${Black};
				width: 100%;
				height: 100px;
				-webkit-border-top-left-radius: 8px;
			    -moz-border-radius-topleft: 8px;
			    border-top-left-radius: 8px;
			    -webkit-border-top-right-radius: 8px;
			    -webkit-background-clip: padding-box;
			    -moz-border-radius-topright: 8px;
			    -moz-background-clip: padding;
			    border-top-right-radius: 8px;
			    background-clip: padding-box;
			    border-bottom: 2px solid ${Purple};
				h4 {
					@media (max-width: 1000px) {
						font-size: 1.5rem;
					}	
					margin: 0 auto;
					width: 100%;
					padding: 15px;
					text-align: center;
				}
			}
			.mIcon {
				margin: -30px auto;
				height: 50px;
				width: 50px;
				padding: 10px;
				border-radius: 50%;
				background-color: ${Purple};
				color: ${White};
				box-shadow: 0px 0px 5px rgba(0,0,0,0.6);
			    -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.6);
			    -moz-box-shadow: 0px 0px 5px rgba(0,0,0,0.6);
			}
			.serviceText {
				height: auto;
				margin: 30px auto;
				width: 100%;
				p {
					text-align: center;
					width: 90%;
					margin: 5px auto;
				}
			}
			.modalHold {
				text-align: center;
				width: 100%;
				${Flex('row', 'wrap')};
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				margin: 15px auto;
				cursor: pointer;
				.modalButtonHolder {
					${Flex('column', 'nowrap')};
					align-items: center;
					justify-content: center;
					width: auto;
					height: 100%;
					p {
						text-align: center;
						width: auto;
						height: auto;
						color: ${Purple};
						margin: 7.5px;
						border-radius: 15px;
						padding: 15px 5px 15px 5px;
						background-color: ${White};
						border: 2px solid ${Purple};
						&:hover {
							color: ${White};
							background-color: ${Black};
						}
					}
				}
			}
		}
	}
`;