import React from 'react';
import { Container } from '../styles/footer';
import pdf from '../images/privacy_policy.pdf';
const Footer = () => {
	return (
		<Container>
			<footer style={{margin: '10px'}}>
				 <p style={{ textAlign: "center" }}>Copyright &copy; 2020. JHG, LLC. All Rights Reserved. <a href='https://fleurtechnologies.com' style={{textDecoration: 'none', color: 'rgb(131, 51, 171)'}}>Powered by Fleur Technologies</a> <a href={pdf}>Privacy Policy</a></p>
			</footer>
		</Container>
	);
}

export default Footer;