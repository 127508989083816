export const Orange = '#E5593F';
export const Purple = '#8B008B';
export const Black = '#0E0F12';
export const White = '#FBFBFC';
export const Grey = '#EAEDED';

// Other Purple Options
// Medium #3F003F
// Dark #450045
// Darkest #370037

