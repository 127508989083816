import styled from 'styled-components';
import { Purple, Black, White, Orange } from './colors';
import { Wrap, Direction, Flex } from './mixins';

export const Container = styled.div`
	width: 100%;
	height: 115px;
	background-color: ${White};
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	nav {
		${Flex('row', 'nowrap')};
		width: 100%;
		height: inherit;
		align-items: center;
		justify-content: space-between;
		img {
			width: auto;
			height: auto;
			margin: 5px;
			padding: 5px;
			@media (max-width: 1000px) {
				height: inherit;
				width: auto;
				position: absolute;
				top: auto;
				margin: 0 auto;
			}
		}
		.middleSection {
			width: 80%;
			text-align: center;
			@media (max-width: 1000px) {
					visibility: hidden;
					display: none;
				}
			h1 {
				
			}
			a {
				text-decoration: none;
				p {
					font-size: 1.45rem;
					padding: 0;
					margin: 0;
					letter-spacing: 15px;
					text-transform: uppercase;
					color: ${Purple};
					@media (max-width: 1000px) {
						letter-spacing: 2.5px;
						font-size: .8rem;
					}
				}

			}
		}
		.contactModalButton {
			width: auto;
			height: 100%;
			color: ${Black};
			margin: 5px;
			border-radius: 15px;
			text-align: center;
			font-size: 1.15rem;
			@media (max-width: 1000px) {
				height: inherit;
				width: auto;
				position: absolute;
				top: 0;
				right: 15px;
			}
			&:hover {
				color: ${Purple};
				cursor: pointer;
			}

		}
		img, h1, p {
			margin: 5px auto;
		}
	}
`;