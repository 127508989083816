export function Wrap(val) {
	return `flex-wrap: ${val}`;
}

export function Direction(val) {
	return `flex-direction: ${val}`;
}

export function Flex(dir, wrap) {
	return `display: flex; flex-direction: ${dir}; flex-wrap: ${wrap}`;
}

export function BR(val) {
	return `border-radius: ${val}px`;
}