import styled from 'styled-components';
import { Purple, Black, White, Orange, Grey } from './colors';
import { Wrap, Direction, Flex, BR } from './mixins';

export const Container = styled.div`
	margin: 0;
	padding: 0;
	width: 100vw;
	height: auto;
	background-color: ${White};
	overflow-x: hidden;
	box-sizing: border-box;
	.header {
		width: 100%;
		height: auto;
		h2 {
			margin: 0 auto;
			text-align: center;
			padding: 7.5px;
		}
	}
	.double {
		width: 100%;
		${Flex('row', 'wrap')};
		justify-content: center;
		padding: 15px 0 15px 0;
		.aboutSection {
			background-color: ${Grey};
			margin: 15px auto;
			@media (max-width: 1000px) {
				width: 85%;
				height: auto;
			}
			width: 48%;
			height: auto;
			border-radius: 15px;
			box-shadow: 0px 0px 12.5px rgba(0,0,0,0.6);
		    -webkit-box-shadow: 0px 0px 12.5px rgba(0,0,0,0.6);
		    -moz-box-shadow: 0px 0px 12.5px rgba(0,0,0,0.6);
			h3 {			
				height: 50px;
				margin: 15px auto;
				text-align: center;
				width: 100%;
				padding: 5px;
			}
			.cta {
				height: 100px;
				width: 100%;
				${Flex('column', 'nowrap')};
				align-items: center;
				justify-content: center;
				border-top: 3px solid ${Purple};
				border-bottom: 3px solid ${Purple};
				background-color: ${White};
				p {
					color: ${Black};
					margin: 0;
					padding: 0;
					font-size: 2rem;
				}
			}
			.contentP {
				@media (max-width: 1000px) {
					height: auto;
				}	
				height: auto;
				width: 100%;
				p {
					width: 90%;
					margin: 0 auto;
					padding: 10px 0 10px 0;
					font-size: 1.4rem;
				}
			}
		}
	}
`;