import styled from 'styled-components';
import { Purple, Black, White, Orange } from './colors';
import { Wrap, Direction, Flex } from './mixins';

export const Container = styled.div`
	margin: 0;
	padding: 0;
	width: 100vw;
	height: auto;
	background-color: ${White};
	overflow-x: hidden;
	box-sizing: border-box;
`;