import styled from 'styled-components';
import { Purple, Black, White, Orange } from './colors';
import { Wrap, Direction, Flex } from './mixins';

export const Container = styled.div`
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100vw;
	height: auto;
	background-color: ${White};
	overflow-x: hidden;
	box-sizing: border-box;
	h1, h2, h3, h4, h5, h6 {
		padding: 0;
		margin: 0;
	}
	h1 {
		font-size: 2.75rem;
	}
	h2 {
		font-size: 2.5rem;
	}
	h3 {
		font-size: 2.25rem;
	}
	h4 {
		font-size: 2rem;
	}
	h5 {
		font-size: 1.75rem;
	}
	h6 {
		font-size: 1.5rem;
	}

h1, h2, h3, h4, h5, h6{
  font-family: 'Libre Baskerville', serif;
}

p, ul{
  font-family: 'Libre Baskerville', serif;
}
`;