import React from 'react';
import { Container } from '../styles/about';

const About = () => {
	return (
		<Container>
			<div className='header'>
				<h2>About JHG</h2>
			</div>
			<div className='double'>
				<div className='aboutSection left'>
					<h3>Our Mission</h3>
					<div className='cta two'>
						<p>Quality Before Revenue</p>
					</div>
					<div className='contentP'>
						<p>Jordan Healthcare Group was built with one goal in mind: to help senior care providers improve their services while ensuring fiscal 
						   responsibility. We believe that quality should be the ultimate driver and measure of success. Quality care will lead too adequate revenue.  
						   We utilize the latest technology and qualitative data to guide our clients' decisions without ever sacrificing customer service. These 
						   distinct principles and our highly focused team of professionals allow JHG to provide a precise and on-target solution to the senior care industry.
						</p>
					</div>
				</div>
				<div className='aboutSection right'>
					<h3>Our Vision</h3>
					<div className='cta one'>
						<p>C.A.R.E.</p>
					</div>
					<div className='contentP'>
						<p>Healthcare is a people business and the JHG vision is to provide the best people to solve the most difficult problems in all aspects of the skilled 
						   and post acute nursing and rehabilitation sector. Our systems based focus and utilization of SMART protocols drives positive results. JHG always puts 
						   the customer at the forefront of CARE - Compassion, Attitude, Respect and Excellence.	
						</p>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default About;