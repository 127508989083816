import React from 'react';
import { Container } from '../styles/home';
import Nav from '../components/nav';
import Hero from '../components/hero';
import About from '../components/about';
import Services from '../components/services';
import Footer from '../components/footer';
const Home = () => {
	return (
		<Container>
			<Nav />
			<Hero />
			<About />
			<Services />
			<Footer />
		</Container>
	);
}

export default Home;