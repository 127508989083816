import styled from 'styled-components';
import { Purple, Black, White, Orange, Grey } from './colors';
import { Wrap, Direction, Flex } from './mixins';

export const Container = styled.div`
	@media (max-width: 1000px) {
		height: auto;
	}
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 500px;
	background-color: ${Grey};
	overflow-x: hidden;
	box-sizing: border-box;
	${Flex('row', 'wrap')};
	align-items: center;
	justify-content: center;
	/*@media (max-width: 1000px) {
		
	}	*/
	.leftSide, .rightSide {
		${Flex('column', 'nowrap')};
		align-items: center;
		justify-content: center;
		@media (max-width: 1000px) {
			height: auto;
			margin: 15px auto;
			padding: 5px;
		}

	}
	.leftSide {
		@media (max-width: 1000px) {
			width: 100%;
		}			
		width: 40%;
		h2 {
			background-color: ${Purple};
			color: ${White};
			padding: 5px;
			width: 50%;
			border-radius: 15px;
			text-align: center;
			margin: 0 auto;
		}
	}
	.rightSide {
		@media (max-width: 1000px) {
			width: 100%;
		}		
		width: 60%;
		p {
			width: 85%;
			margin: 0 auto;
			font-size: 1.55rem;
		}
	}
`;