import React from 'react';
import { Container } from '../styles/hero';

const Hero = () => {
	return (
		<Container>
			<div className='leftSide'>
				<h2>COVID-19</h2>
			</div>
			<div className='rightSide'>
				<p>Contact us for any questions on protocols, reimbursment, proper use of PPE, notification to public health, 
				   families, and employees. We have designed programs that provide real-time solutions to mobilize front line staff 
				   and assist your employees in staying safe and working to keep their families safe while caring for your residents. 
				</p>
			</div>
		</Container>
	);
}

export default Hero;